<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-clipboard"></i> Handlers
        </h2>

        <div style="background-color: white; padding: 2%">
            <div class="row listing" v-if="!editor">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-inline">
                                <div class="form-group mr-1">
                                    <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                                        <h5>Domain</h5>
                                        <input
                                            type="text"
                                            v-model="filter.search"
                                            class="form-control"
                                            placeholder="Search Domain"
                                        />
                                    </div>
                                </div>
                                <div class="form-group ml-3 mr-2">
                                    <div class="tabs-wrap p1-3" style="padding-top: 30px; padding-bottom: 30px">
                                        <h5>Handler Type</h5>
                                        <select v-model="filter.handlerType" class="form-control">
                                            <option value="" selected>-- Any Handler Type --</option>
                                            <option value="conversion">Goal Conversion</option>
                                            <option value="scraper">Data Scraper</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group ml-3 mr-2">
                                    <div class="tabs-wrap p1-3" style="padding-top: 30px; padding-bottom: 30px">
                                        <h5>Status</h5>
                                        <select v-model="filter.status" class="form-control">
                                            <option value="" selected>-- Any Status--</option>
                                            <option value="active">Active</option>
                                            <option value="disabled">Disabled</option>
                                            <option value="archive">Archived</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group ml-3 mr-2">
                                    <div class="tabs-wrap p1-3" style="padding-top: 30px; padding-bottom: 30px">
                                        <h5>Max Size</h5>
                                        <select v-model="max" class="form-control">
                                            <option value="20" selected>20</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="tabs-wrap mt-4" style="padding-top: 40px; padding-bottom: 30px">
                                    <button
                                        type="button"
                                        class="btn searchBtn text-white mr-1"
                                        style="background-color: #383838; "
                                        @click="search"
                                    >
                                        <i class="fa fa-search" title="Search"></i>
                                        <span> Search Domains</span>
                                    </button>

                                    <button
                                        type="button"
                                        class="btn searchBtn text-white mr-1"
                                        style="background-color: #383838"
                                        @click="creator"
                                    >
                                        <i class="fa fa-plus" title="Create"></i>
                                        <span> Create Handler</span>
                                    </button>
                                </div>
                                <!--<button type="button" class="btn btn-success" @click="create"><i class="fa fa-plus" title="New"></i></button>-->
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div class="listing table-responsive">
                        <table class="table table-hover bg-light text-dark">
                            <thead>
                            <tr>
                                <td>Date</td>
                                <td>Domain</td>
                                <td>Path</td>
                                <td>Type</td>
                                <td>Status</td>
                                <td>First</td>
                                <td>Author</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="data in items.data" v-bind:key="data._id">
                                <td>{{ data.date | date_format }}</td>
                                <td >{{ data.domain }}</td>
                                <td >{{ data.pathname }}</td>
                                <td >{{ data.handlerType || "N/A" }}</td>
                                <td >{{ data.status }}</td>
                                <td >{{ data.first }}</td>
                                <td >{{ data.author || "N/A" }}</td>
                                <td >
                                    <div class="btn-group icons">
                                        <button class="btn btn-info" @click="edit(data)">
                                            <span class="fa fa-pencil" title="View"></span>
                                        </button>
                                        <button class="btn btn-danger" @click="remove(data)">
                                            <span class="fa fa-trash" title="Delete"></span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="row">
                            <div class="col-12 col-md-12 col-sm-12">
                                <v-pagination
                                    v-model="page"
                                    :pageCount="Math.ceil(count / max)"
                                    :classes="bootstrapPaginationClasses"
                                    :labels="customLabels"
                                    @input="search"
                                ></v-pagination>
                            </div>
                            <div class="col-12 col-sm-3 text-right">
                                <span>{{currentCount}}</span> of <span>{{count}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row editor" v-if="editor">
                <div class="col-sm-9 col-xs-12">
                    <div class="form-group">
                        <codemirrow :item="item.code" :FillItem="editCode" />
                    </div>

                    <div class="form-group col-12"  v-if="item._id">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Change logs
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        <div class="list-group">
                                            <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">{{user.name}}</h5>
                                                    <small class="text-muted">{{new Date().toLocaleTimeString()}}</small>
                                                </div>
                                                <small class="text-muted">{{item.clog}}</small>
                                                <p class="mb-1">{{item.code}}</p>
                                            </a>
                                            <a href="#" class="list-group-item list-group-item-action flex-column align-items-start" v-for="(riv, index) in useRev" :key="index">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">{{riv.author}}</h5>
                                                    <small class="text-muted">{{ new Date(riv.date).toLocaleTimeString()|| new Date().toLocaleTimeString()}}</small>
                                                </div>
                                                <small class="text-muted">{{riv.info}}</small>
                                                <p class="mb-1">{{riv.code}}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-xs-12">
                    <div class="form-group">
                        <label>Domain</label>
                        <input
                            type="text"
                            v-model="item.domain"
                            class="form-control required"
                            required
                        />
                    </div>

                    <div class="form-group">
                        <label>Pathname</label>
                        <input
                            type="text"
                            v-model="item.pathname"
                            class="form-control required"
                            required
                        />
                    </div>
                    <div class="form-group">
                        <label>Type</label>
                        <select
                            v-model="item.handlerType"
                            class="form-control required"
                            required
                        >
                            <option value="conversion">Goal Conversion</option>
                            <option value="scraper">Data Scraper</option>

                        </select>
                    </div>
                    <div class="form-group">
                        <label>First</label>
                        <select
                            v-model="item.first"
                            class="form-control required"
                            required
                        >
                            <option value="yes">Yes</option>
                            <option value="no" selected>No</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Status</label>
                        <select
                            v-model="item.status"
                            class="form-control required"
                            required
                        >
                            <option value="active" selected>Active</option>
                            <option value="disabled">Disabled</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Is Subhandler</label>
                        <select
                            v-model="item.subhandler"
                            class="form-control required"
                            required
                        >
                            <option :value="true">True</option>
                            <option :value="false">False</option>
                        </select>
                    </div>
                    <div class="form-group" v-if ="item.payload">
                        <label>View Handler Events</label>
                        <b-button class="form-control " v-b-modal.modal-scrollable>Events</b-button>

                        <b-modal id="modal-scrollable" scrollable title="Scrollable Content" ok-only>
                            <div class="accordion" role="tablist">
                                <b-card no-body class="mb-1" v-for="(item,i) in item.payload.list" :key="i+=1">
                                    <b-card-header header-tag="header" class="p-1 col-12 d-flex" role="tab">
                                        <b-button block   v-b-toggle="`accordion-${i}`" variant="info" class="col-8 mr-4">Event {{i}}</b-button>

                                        <b-button  variant="danger" class="col-3 ml-4"   @click.prevent="deleteEvents(i)">Remove Event</b-button>
                                    </b-card-header>
                                    <b-collapse :id="`accordion-${i}`"  accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <ejs-grid ref="grid" :dataSource="item.events" :allowPaging="true" :toolbar='toolbar'  :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings" :recordClick="request">
                                                <e-columns>
                                                    <e-column field="type" headerText="Type" text-Align="Left" width="50"></e-column>
                                                    <e-column field="xpath" headerText="Xpath" text-Align="Left" width="250"></e-column>
<!--                                                    <e-column field="delay" headerText="Delay" text-Align="Left" width="50"></e-column>-->
                                                </e-columns>
                                            </ejs-grid>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>

                        </b-modal>
                    </div>
                    <div class="form-group">
                        <label>Author</label>
                        <input
                            type="text"
                            v-model="item.author"
                            class="form-control required"
                            readonly
                        />
                    </div>

                    <div class="form-group" v-if="item._id">
                        <label>Change Request</label>
                        <textarea
                            rows="10"
                            v-model="item.clog"
                            class="form-control"
                        ></textarea>
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-success btn-lg btn-block" @click.prevent="save(false)">
                            <span class="glyphicon glyphicon-ok"></span>  Save Changes
                        </button>

                        <button
                            type="button"
                            class="btn btn-secondary btn-block"
                            @click.prevent="cancel"
                        >
                            <span class="glyphicon glyphicon-remove"></span> Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import vPagination from "vue-plain-pagination";
import codemirrow from "../components/shared/mirror.vue";
import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Filter, Group,Toolbar} from '@syncfusion/ej2-vue-grids';
import Vue from "vue";
Vue.use(GridPlugin);
export default {
    name: "Handlers",
    props: ["user"],
    components: {
        vPagination,
        codemirrow,
    },
    data: function () {
        return {
            item: {subhandler: false, handlerType:"conversion"},
            oldCode:'',
            useRev:[],
            currentCount:0,
            items: { total: 0, data: [] },
            editor: false,
            filter: { name: "", status: "",  handlerType: ""  },
            count:0,
            max: 25,
            page: 1,
            bootstrapPaginationClasses: {
                ul: "pagination",
                li: "page-item",
                liActive: "active",
                liDisable: "disabled",
                button: "page-link",
            },
            customLabels: {
                first: "First",
                prev: "Previous",
                next: "Next",
                last: "Last",
            },
            searching: false,
            pageSettings: { pageSize: 50, pageSizes: true },

        };
    },
    mounted: function () {
        // window.addEventListener("keydown",this.windowListener);
        // this.load();
    },
    destroyed: function () {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        windowListener: function (e) {
            if (e.keyCode === 13 && !this.editor && !this.searching) {
                // this.search();
            }
        },
        create: function () {
            this.item = {revision:[]};
            this.editor = true;
        },
        save: function (edit) {
            if(this.item._id){
                // this.item.revision.push({
                //     date: new Date(),
                //     code: this.oldCode,
                //     author: this.user.name
                // });
                // this.item.revision[this.item.revision.length -1].r_num = this.item.revision.length;
                // this.item.revision[this.item.revision.length -1].info = this.item.clog;
            }else{
                this.item.date = new Date();
                this.item.author = this.item.author ? this.item.author : this.user.name
            }
            //Handle saving the changes
            var request = {
                apikey: this.$root.apikey,
                id: this.item._id,
                args: this.item,
            };
            // this.$root.preloader = true;

            axios({
                url: `${this.$root.serverUrl}/admin/handlers`,
                data: request,
                responseType: "json",
                method: this.item["_id"] ? "POST" : "PUT",
            })
                .then(
                    function (resp) {
                        //Process the results
                        this.$root.preloader = false;
                        if (resp.data && !resp.data.error) {
                            //Saved successfully, now update the parent
                            swal({
                                title: "Notice",
                                text: "Your changes were saved successfully",
                                icon: "success",
                            }).then(
                                function (val) {
                                    if (val) {
                                        this.editor = edit;
                                        if(edit ==  false) this.search();
                                    }
                                }.bind(this)
                            );
                        } else {
                            //Handle errors
                            this.errormsg =
                                resp.data && resp.data.error ? resp.data.error : false;
                            this.$root.preloader = false;
                        }
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        this.$root.preloader = false;
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }.bind(this)
                );
        },
        remove: function (obj) {
            //Handle removing the item
            swal({
                title: "Remove",
                text: "Are you sure",
                icon: "info",
                buttons: ["No", "Yes"],
            }).then(
                function (val) {
                    if (!val) return;

                    var request = { apikey: this.$root.apikey, id: obj._id };
                    this.$root.preloader = true;

                    axios({
                        url: `${this.$root.serverUrl}/admin/handlers/${obj._id}`,
                        data: request,
                        responseType: "json",
                        method: "DELETE",
                    })
                        .then(
                            function (resp) {
                                //Process the results
                                this.$root.preloader = false;
                                if (resp.data && !resp.data.error) {
                                    //Saved successfully, now update the parent
                                    swal({
                                        title: "Remove",
                                        text: "Your request was completed successfully",
                                        icon: "success",
                                    }).then(
                                        function () {
                                            this.search();
                                        }.bind(this)
                                    );
                                } else {
                                    //Handle errors
                                    swal({
                                        title: "Remove",
                                        text: "Unable to complete your request",
                                        icon: "error",
                                    });
                                }
                            }.bind(this)
                        )
                        .catch(
                            function (err) {
                                this.$root.preloader = false;
                                this.errormsg = err.message || "An unexpected error occured";
                                swal({ title: "Error", text: this.errormsg, icon: "error" });
                            }.bind(this)
                        );
                }.bind(this)
            );
        },
        creator: function () {
            //Edit the vendor item
            this.editor = true;
            this.item = Object.clone(
                {},
                {
                    handlerType: "conversion",
                    subhandler: false,
                    first: "no",
                    status: "active",
                    pathname: "/",
                    domain: "",
                    author: this.user.name,
                    revision: [],
                    clog: "",
                }
            );
        },
        edit: function (obj) {
            //Edit the vendor item
            this.editor = true;
            this.item = Object.clone({}, obj);
            this.oldCode = this.item.code || '';
            this.item.clog = '';
            this.item.author = this.item.author ? this.item.author : this.user.name;
            this.item.handlerType = this.item.handlerType ? this.item.handlerType : "conversion";
            this.item.subhandler = this.item.subhandler ? this.item.subhandler : false;
            // if(!this.item.revision) this.item.revision = [];
            // if(this.item.revision.length > 0 ){
            //     if(this.item.revision.length > 10 ){
            //         for(let i = 1; i < 10; i++){
            //             let index = this.item.revision.length-i;
            //             this.useRev.push(this.item.revision[index])
            //         }
            //     }
            //     else{
            //         for(let i = 1; i < this.item.revision.length; i++){
            //             let index = this.item.revision.length-i;
            //             this.useRev.push(this.item.revision[index])
            //         }
            //     }
            //     //   this.useRev = this.item.revision || this.useRev;
            // }
        },
        editCode: function (code) {
            //Edit the vendor item
            this.item.code = code;
        },
        cancel: function () {
            this.editor = false;
            this.errormsg = false;
        },
        load: function () {
            this.searching = true;
            //Load the items
            var page;
            if (
                event &&
                event.type == "click" &&
                event.currentTarget &&
                event.currentTarget.className.indexOf(
                    this.bootstrapPaginationClasses.button
                ) > -1
            ) {
                //Pagination triggered event
                console.log(
                    `Pagination event: ${this.page}: ${event.currentTarget.className}`
                );
                page = this.page ? (this.page == 1 ? 0 : Number(this.page - 1)) : 0;
            } else page = 0;

            var request = { apikey: this.$root.apikey, sSearch: null,handlerType:null, filters: {} };
            if (this.filter.search) request.sSearch = this.filter.search;
            if (this.filter.handlerType) request.handlerType = this.filter.handlerType;
            if (this.filter.type) request.filters.type = this.filter.type;
            if (this.filter.status) request.filters.status = this.filter.status;
            request.page = page;
            request.max = this.max.toInteger();
            this.$root.preloader = true;
            if (!Object.keys(request.filters)) delete request.filters;

            ///TODO fix search filters
            axios
                .get(`${this.$root.serverUrl}/admin/handlers`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.items = resp.data ? resp.data : { total: 0, data: [] };
                        this.$root.preloader = false;
                        this.searching = false;
                    }.bind(this)
                );
        },
        search: function () {
            this.searching = true;
            //Load the items
            // var page;
            // if (
            //   event &&
            //   event.type == "click" &&
            //   event.currentTarget &&
            //   event.currentTarget.className.indexOf(
            //     this.bootstrapPaginationClasses.button
            //   ) > -1
            // ) {
            //   //Pagination triggered event
            //   console.log(
            //     `Pagination event: ${this.page}: ${event.currentTarget.className}`
            //   );
            //   page = this.page ? (this.page == 1 ? 0 : Number(this.page - 1)) : 0;
            // } else page = 0;

            if(this.page == 0) {
                this.page = 1;
            }

            var request = { apikey: this.$root.apikey, sSearch: null, filters: {} };
            if (this.filter.search) request.sSearch = this.filter.search;
            if (this.filter.handlerType) request.handlerType = this.filter.handlerType;
            if (this.filter.status) request.filters.status = this.filter.status;
            request.page = this.page;
            request.max = this.max;
            this.$root.preloader = true;
            if (!Object.keys(request.filters)) delete request.filters;

            ///TODO fix search filters
            axios
                .get(`${this.$root.serverUrl}/admin/handlers`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.items = resp.data ? resp.data : { total: 0, data: [] };
                        this.count = this.items.total;

                        this.currentCount = this.max * this.page;

                        if(this.currentCount % this.max != 0 || this.currentCount > this.items.total) {
                            this.currentCount = this.max * (this.page-1)
                            this.currentCount+=this.items.data.length
                        }

                        this.$root.preloader = false;
                        this.searching = false;
                    }.bind(this)
                );
        },
        deleteEvents: function (index){
            swal({title:"Delete", text:"Are you sure? Deleting is final", icon:"info", buttons:['No','Yes']}).then((val) => {
                if (!val) return;
                index -=1
                console.log(index)
                this.item.payload.list.splice(index, 1);
                this.save(true)
            });


        }
    },
    provide: {
        grid: [Page, Sort, Filter,Group,Toolbar,PdfExport,ExcelExport]
    },
};
</script>

<style scoped>
.searchBtn span {
    max-width: 0;
    -webkit-transition: max-width 1s;
    -moz-transition: max-width 1s;
    -o-transition: max-width 1s;
    transition: max-width 1s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
}
button:hover span {
    max-width: 9rem;
}
</style>

